import React from "react";

const Icon = props => (
  <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" focusable="false" {...props}>
    <path fill="currentColor" fillRule="evenodd" d="M15 23.18A8.18 8.18 0 1 0 6.82 15 8.18 8.18 0 0 0 15 23.18Zm0-2.73A5.45 5.45 0 1 0 9.55 15 5.45 5.45 0 0 0 15 20.45Z" />
    <path fill="currentColor" d="M23.18 5.45a1.37 1.37 0 1 0 1.37 1.37 1.37 1.37 0 0 0-1.37-1.37Z" />
    <path fill="currentColor" fillRule="evenodd" d="M.89 4.47C0 6.22 0 8.51 0 13.09v3.82c0 4.58 0 6.87.89 8.62a8.25 8.25 0 0 0 3.58 3.58c1.75.89 4 .89 8.62.89h3.82c4.58 0 6.87 0 8.62-.89a8.25 8.25 0 0 0 3.58-3.58c.89-1.75.89-4 .89-8.62v-3.82c0-4.58 0-6.87-.89-8.62A8.25 8.25 0 0 0 25.53.89C23.78 0 21.49 0 16.91 0h-3.82C8.51 0 6.22 0 4.47.89A8.25 8.25 0 0 0 .89 4.47Zm16-1.74h-3.8c-2.34 0-3.92 0-5.15.1a5.81 5.81 0 0 0-2.23.49 5.42 5.42 0 0 0-2.39 2.39 5.81 5.81 0 0 0-.49 2.23c-.1 1.23-.1 2.81-.1 5.15v3.82c0 2.34 0 3.92.1 5.15a5.81 5.81 0 0 0 .49 2.23 5.42 5.42 0 0 0 2.39 2.39 5.81 5.81 0 0 0 2.23.49c1.23.1 2.81.1 5.15.1h3.82c2.34 0 3.92 0 5.15-.1a5.81 5.81 0 0 0 2.23-.49 5.42 5.42 0 0 0 2.39-2.39 5.81 5.81 0 0 0 .49-2.23c.1-1.23.1-2.81.1-5.15v-3.82c0-2.34 0-3.92-.1-5.15a5.81 5.81 0 0 0-.49-2.23 5.42 5.42 0 0 0-2.39-2.39 5.81 5.81 0 0 0-2.23-.49c-1.23-.1-2.81-.1-5.15-.1Z" />
  </svg>
);

export default Icon;
