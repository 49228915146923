import React from "react";

const Icon = props => (
  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" focusable="false" {...props}>
    <path fill="currentColor" d="M198.91 73.48c-.41-2.33-2.11-5.12-7.87-5.12H9c-5.76 0-7.46 2.79-7.87 5.12a6.08 6.08 0 0 0 1.1 4.72 9.31 9.31 0 0 0 3.58 2.61 6.4 6.4 0 0 1 1.71 1c1.06 1.09 1.25 3 1.46 5 0 .29 0 .56.08.84.36 3.21.84 6.49 1.43 9.75 1.17 6.47 2.5 13.81 5.26 20.18 2.53 5.86 6.63 9.81 12.18 11.72 6.17 2.13 12.86 2.31 18.64 2.31h5.83c5 0 10.58 0 16.08-.34.71-.05 1.44-.08 2.18-.12 5.58-.26 11.9-.57 16.71-4.39 3.31-2.62 5.11-6.54 6.53-10a65 65 0 0 0 2.6-8.3c.31-1.16.63-2.37 1-3.52.06-.23.13-.46.2-.7.48-1.7 1-3.62 2.13-4.13a.65.65 0 0 1 .19 0 .54.54 0 0 1 .2 0c1.12.51 1.66 2.43 2.14 4.13.07.24.14.47.2.7.34 1.15.66 2.36 1 3.52a65 65 0 0 0 2.6 8.3c1.42 3.47 3.22 7.39 6.53 10 4.81 3.82 11.13 4.13 16.71 4.39.74 0 1.47.07 2.18.12 5.5.33 11.12.34 16.08.34h5.83c5.78 0 12.47-.18 18.64-2.31 5.55-1.91 9.65-5.86 12.18-11.72 2.76-6.37 4.09-13.71 5.26-20.18.59-3.26 1.07-6.54 1.43-9.75 0-.28.05-.55.08-.84.21-2 .4-3.89 1.45-5a6.65 6.65 0 0 1 1.72-1 9.31 9.31 0 0 0 3.58-2.61 6.08 6.08 0 0 0 1.08-4.72Z" />
    <path fill="#fec901" d="M24.83 80.86A7.37 7.37 0 0 0 23 82.05c-2.36 2.09-2.17 5.22-1.83 8.08A108.05 108.05 0 0 0 25 107.91c.74 2.51 1.5 5.16 3.23 7.19a15 15 0 0 0 10.28 4.74 264.76 264.76 0 0 0 27.89.14c.84 0 1.69-.07 2.53-.12 3.19-.18 6.37-.64 9.15-2.33a12 12 0 0 0 4.34-5 65.45 65.45 0 0 0 3.52-9.52 94.93 94.93 0 0 0 2.64-10.31c.75-3.68 1-8.14-2.23-10.75-3-2.4-7.51-2-11.08-2.08-7.31-.1-14.62-.08-21.93-.05s-14.55-.13-21.82 0a25.07 25.07 0 0 0-4.24.32 10.42 10.42 0 0 0-2.45.72ZM114.77 80.86a7.55 7.55 0 0 0-1.86 1.19c-2.36 2.09-2.16 5.22-1.83 8.08a108.05 108.05 0 0 0 3.81 17.78c.75 2.51 1.5 5.16 3.23 7.19a15 15 0 0 0 10.28 4.74 264.77 264.77 0 0 0 27.89.14c.84 0 1.69-.07 2.53-.12 3.19-.18 6.38-.64 9.16-2.33a12.08 12.08 0 0 0 4.33-5 65.45 65.45 0 0 0 3.52-9.52 94.93 94.93 0 0 0 2.69-10.36c.75-3.68 1-8.14-2.23-10.75-3-2.4-7.5-2-11.07-2.08-7.32-.1-14.63-.08-21.94-.05s-14.55-.13-21.82 0a25.07 25.07 0 0 0-4.24.32 10.57 10.57 0 0 0-2.45.77Z" />
  </svg>
);

export default Icon;
