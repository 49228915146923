import React from 'react';

// import _shuffle from 'lodash/shuffle';
import Header from '../components/sections/Header';
import Opener from '../components/sections/Opener';
// import Tweets from '../components/sections/Tweets';
import TextParallax from '../components/sections/TextParallax';
import TextImg from '../components/sections/TextImg';
import ImgGallery from '../components/sections/ImgGallery';
import Video from '../components/sections/Video';
import Accordion from '../components/sections/Accordion';
import Footer from '../components/sections/Footer';
// import MusicPlayer from '../components/sections/MusicPlayer';

import TelegramIcon from '../components/icons/Telegram';
import XIcon from '../components/icons/X';
import DexToolsIcon from '../components/icons/DexTools';
// import UniswapIcon from '../components/icons/Uniswap';
import SushiSwapIcon from '../components/icons/SushiSwap';
// import KyberSwapIcon from '../components/icons/KyberSwap';
// import EtherscanIcon from '../components/icons/Etherscan';
import ShadesIcon from '../components/icons/Shades';
import InstagramIcon from '../components/icons/Instagram';
import MediumIcon from '../components/icons/Medium';

// import Footer from '../components/sections/Footer';

/**
 * App config.
 */
const APP_TITLE = 'Andy'; // General app title.
const APP_TAB_TITLE = 'Andy on Base'; // Default browser tab title.
const TOKEN_SYMBOL = 'ANDY';
const MAX_CONTAINER_WIDTH_PX = 1200;
const CONTAINER_GUTTER_HORIZONTAL_REM = 1.25;

/**
 * Socials.
 */
const socials = {
  links: [
    { id: 'TELEGRAM', title: 'Telegram', url: 'https://t.me/AndyOnBasePortal', iconComponent: TelegramIcon }, // Comment out to omit.
    { id: 'X', title: 'X', url: 'https://x.com/_AndyOnBase', iconComponent: XIcon }, // Comment out to omit.
    // { id: 'UNISWAP', title: 'Uniswap', url: 'https://app.uniswap.org/swap', iconComponent: UniswapIcon }, // Comment out to omit.
    { id: 'SUSHISWAP', title: 'SushiSwap', url: 'https://www.sushi.com/swap?chainId=8453&token1=0x18A8BD1fe17A1BB9FFB39eCD83E9489cfD17a022', iconComponent: SushiSwapIcon }, // Comment out to omit.
    { id: 'DEX_TOOLS', title: 'DexTools', url: 'https://www.dextools.io/app/en/base/pair-explorer/0xff5375bd65056dbe6119256fc3be2eb0ffa8a840', iconComponent: DexToolsIcon }, // Comment out to omit.
    // { id: 'KYBERSWAP', title: 'KyberSwap', url: 'https://kyberswap.com/swap/base/', iconComponent: KyberSwapIcon }, // Comment out to omit.
    // { id: 'ETHERSCAN', title: 'BaseScan', url: 'https://basescan.org/address/0x18A8BD1fe17A1BB9FFB39eCD83E9489cfD17a022', iconComponent: EtherscanIcon }, // Comment out to omit.
    { id: 'INSTAGRAM', title: 'Instagram', url: 'https://www.instagram.com/andyonbase/', iconComponent: InstagramIcon }, // Comment out to omit.
    { id: 'MEDIUM', title: 'Medium', url: 'https://medium.com/@AndyOnBase', iconComponent: MediumIcon }, // Comment out to omit.
    { id: 'SHADES', title: 'Avatar Maker', url: '/avatar.htm?a=2', iconComponent: ShadesIcon }, // Comment out to omit.
  ],
};

const socialsBp = {
  links: [
    { id: 'TELEGRAM', title: 'Telegram', url: 'https://t.me/AndyOnBasePortal', iconComponent: TelegramIcon }, // Comment out to omit.
    { id: 'X', title: 'X', url: 'https://x.com/_AndyOnBase', iconComponent: XIcon }, // Comment out to omit.
    // { id: 'UNISWAP', title: 'Uniswap', url: 'https://app.uniswap.org/swap', iconComponent: UniswapIcon }, // Comment out to omit.
    { id: 'SUSHISWAP', title: 'SushiSwap', url: 'https://www.sushi.com/swap?chainId=8453&token1=0x18A8BD1fe17A1BB9FFB39eCD83E9489cfD17a022', iconComponent: SushiSwapIcon }, // Comment out to omit.
    { id: 'DEX_TOOLS', title: 'DexTools', url: 'https://www.dextools.io/app/en/base/pair-explorer/0xff5375bd65056dbe6119256fc3be2eb0ffa8a840', iconComponent: DexToolsIcon }, // Comment out to omit.
    // { id: 'KYBERSWAP', title: 'KyberSwap', url: 'https://kyberswap.com/swap/base/', iconComponent: KyberSwapIcon }, // Comment out to omit.
    // { id: 'ETHERSCAN', title: 'BaseScan', url: 'https://basescan.org/address/0x18A8BD1fe17A1BB9FFB39eCD83E9489cfD17a022', iconComponent: EtherscanIcon }, // Comment out to omit.
    { id: 'SHADES', title: 'Avatar Generator', url: '/avatar.htm', iconComponent: ShadesIcon }, // Comment out to omit.
  ],
};

/**
 * Sections.
 */
const sections = [
  /**
   * Header.
   */
  {
    component: () => <Header config={{
      isFixed: true, // When true the header always remains at the top of the page.
      bgColor: 'rgba(28, 28, 28, 0.1)', // Set to 'transparent', or a full / rgba color (rgba to apply 'bgBlur') e.g. '#CCCCCC' / 'rgba(137, 137, 137, 0.2)'.
      bgBlur: '8px', // Apply gaussian blur e.g. '8px' | falsy for none.
      logoSrc: 'header-logo-01.png',
      logoWidthMinPx: 40, // Required | applied at X breakpoint.
      logoWidthMaxPx: 76, // Required | applied at X breakpoint.
      logoTitle: APP_TITLE,
      socials,
      socialsBp,
    }} />,
  },
  /**
   * Opener.
   */
  {
    component: () => <Opener config={{
      alignId: 'TEXT_RIGHT', // Required | 'CENTER' / 'TEXT_LEFT' / 'TEXT_RIGHT'.
      alignContent: 'center', // Required | 'center' / 'flex-start'.
      title: 'Andy', // Optional | falsy to omit.
      titleColorId: 'tertiary.main', // Required | 'primary.main' / 'secondary.main' / 'tertiary.main'.
      subTitle: 'Pepe\'s mischievous roommate has arrived on Base, ready to make waves', // Optional | falsy to omit.
      subTitleColorId: 'quaternary.main', // Required | 'primary.main' / 'secondary.main' / 'tertiary.main'.
      isTitleBeforeSubTitle: true, // Required | when false the H2 appears before H1.
      imgSrc: 'andy-anim-1.gif', // Optional | falsy for no image.
      imgWidthPx: 400, // Required when imgSrc exists.
      bgColor: 'transparent', // Required | full hex color or 'transparent' | 'transparent' if a bgImgSrc is configured.
      bgImgSrc: 'bg-1.jpg', // Can act as a mask for the video below | e.g. 'appBgImg.png' | falsy for no image.
      isBgImgVideoMask: true, // When false the image has a lower z-index.
      bgImgOpacity: 1, // 0.0 to 1 range.
      bgImgOpacityVideoMask: 0.2, // 0.0 to 1 range.
      bgVideoSrc: '', // e.g. 'test.mp4' | falsy for no video.
    }} />,
  },
  /**
   * Text parallax.
   */
  {
    component: () => <TextParallax config={{
      title: '', // Optional | falsy to omit.
      titleBgColor: '', // Required | HEX color or falsy for no color.
      bgColor: 'transparent', // Required | full hex color or 'transparent' | 'transparent' if a bgImgSrc is configured.
      bgImgSrc: 'bg-8.jpg', // e.g. 'test.png' | falsy for no image.
      minHeight: '100vh',
    }} />,
  },
  /**
   * Tweets.
   */
  // {
  //   component: () => <Tweets config={{
  //     title: ''.toUpperCase(), // Optional | falsy to omit.
  //     titleColorId: '#ffffff', // Required | 'primary.main' / 'secondary.main' / 'tertiary.main'.
  //     titleBgColor: 'primary.main', // Optional | HEX / MUI color or falsy for no color.
  //     subTitle: ''.toUpperCase(), // Optional | falsy to omit.
  //     subTitleColorId: '#ffffff', // Required | 'primary.main' / 'secondary.main' / 'tertiary.main'.
  //     subTitleBgColor: 'primary.main', // Optional | HEX / MUI color or falsy for no color.
  //     isTitleBeforeSubTitle: false, // Required | when false the H2 appears before H1.
  //     bgColor: 'transparent', // Required | full hex color or 'transparent' | 'transparent' if a bgImgSrc is configured.
  //     bgImgSrc: '', // Optional | e.g. 'test.png' | falsy for no image.
  //     tweetIds: [ // Required | at least one must be included.
  //       '',
  //     ],
  //     pageBtnBgBorderRadius: '50%', // Required | e.g. '0rem', '0.5rem', '50%'.
  //     tweetPagnationLimit: 3,
  //   }} />,
  // },
  /**
   * Text image.
   */
  {
    component: () => <TextImg config={{
      alignId: 'LEFT', // Required | 'CENTER' / 'TEXT_LEFT' / 'TEXT_RIGHT'.
      alignContent: 'center', // Required | 'center' / 'flex-start'.
      title: 'Who is Andy?', // Optional | falsy to omit.
      titleColorId: 'tertiary.main', // Required | 'primary.main' / 'secondary.main'.
      titleBgColor: '', // Optional | HEX color or falsy for no color.
      subTitle: '', // Optional | falsy to omit.
      subTitleColorId: '', // Required | 'primary.main' / 'secondary.main'.
      subTitleBgColor: '', // Optional | HEX color or falsy for no color.
      isTitleBeforeSubTitle: false, // Required | when false the H2 appears before H1.
      paragraphs: [ // Optional | can include HTML content.
        'Along with best friends Pepe, Brett and Landwolf, Andy\'s iconic character featured in Matt Furie\'s now classic Boy\'s Club comic book series.',
        'Andy is a lovable weirdo that spends his days enjoying drug-fueled hedonism and impish mischief. Always pranking, always laughing, he\'s one of the boys.',
        'WOLF on Solana passed a 230M market cap.',
        'BRETT on Base passed a 1.9B market cap.',
        'PEPE on Ethereum passed a 7.2B market cap.',
        'Where will BASE\'d ANDY go?',
      ],
      imgSrc: 'andy-anim-2.gif', // Optional | falsy for no image.
      imgWidthPx: 400, // Required when imgSrc exists.
      bgColor: 'transparent', // Required | full hex color or 'transparent' | 'transparent' if a bgImgSrc is configured.
      bgImgSrc: 'bg-3.jpg', // e.g. 'appBgImg.png' | falsy for no image.
    }} />,
  },
  /**
   * Text parallax.
   */
  {
    component: () => <TextParallax config={{
      title: '', // Optional | falsy to omit.
      titleBgColor: '', // Required | HEX color or falsy for no color.
      bgColor: 'transparent', // Required | full hex color or 'transparent' | 'transparent' if a bgImgSrc is configured.
      bgImgSrc: 'bg-2.jpg', // e.g. 'test.png' | falsy for no image.
      minHeight: '100vh',
    }} />,
  },
  /**
   * Text image.
   */
  {
    component: () => <TextImg config={{
      alignId: 'LEFT', // Required | 'CENTER' / 'TEXT_LEFT' / 'TEXT_RIGHT'.
      alignContent: 'flex-start', // Required | 'center' / 'flex-start'.
      title: 'The Foundation', // Optional | falsy to omit.
      titleColorId: 'tertiary.main', // Required | 'primary.main' / 'secondary.main'.
      titleBgColor: '', // Optional | HEX color or falsy for no color.
      subTitle: '', // Optional | falsy to omit.
      subTitleColorId: '', // Required | 'primary.main' / 'secondary.main'.
      subTitleBgColor: '', // Optional | HEX color or falsy for no color.
      isTitleBeforeSubTitle: false, // Required | when false the H2 appears before H1.
      paragraphs: [ // Optional | can include HTML content.
        'A solid <em>base</em> is key, so first we settle in by forming an inviting, supportive, friendly community. The ethos is chilled.',
        'Meanwhile, the team onboards influencers &amp; supporters, creates entertaining viral content, hosts Spaces on X, rolls out marketing campaigns, organises CEX listings, donates, and keeps the pad stocked with jerky &amp; beer.',
        'As a holder, aim to contribute at least <em>once</em> per day. Create content, spread the word, help fellow community members, run a VC...whatever flows.',
        'Soon, we\'ll have established ourselves as one of the strongest projects not just on Base (the upcoming normie chain) but within the entire memecoin arena, fully prepared to skyrocket beyond 1 billion MC.',
        'Have fun and enjoy the moment - this is the Andy way.',
      ],
      imgSrc: 'andy-anim-3.gif', // Optional | falsy for no image.
      imgWidthPx: 400, // Required when imgSrc exists.
      bgColor: 'transparent', // Required | full hex color or 'transparent' | 'transparent' if a bgImgSrc is configured.
      bgImgSrc: 'bg-6.jpg', // e.g. 'appBgImg.png' | falsy for no image.
    }} />,
  },
  /**
   * Image gallery.
   */
  {
    component: () => <ImgGallery config={{
      title: ''.toUpperCase(), // Optional | falsy to omit.
      titleColorId: '#ffffff', // Required | 'primary.main' / 'secondary.main'.
      titleBgColor: 'primary.main', // Optional | HEX color or falsy for no color.
      subTitle: ''.toUpperCase(), // Optional | falsy to omit.
      subTitleColorId: '', // Required | 'primary.main' / 'secondary.main'.
      subTitleBgColor: '', // Optional | HEX color or falsy for no color.
      isTitleBeforeSubTitle: true, // Required | when false the H2 appears before H1.
      bgColor: 'transparent', // Required | full hex color or 'transparent' | 'transparent' if a bgImgSrc is configured.
      bgImgSrc: '', // Optional | e.g. 'test.png' | falsy for no image.
      galleryImgs: [ // Required | at least one row with one image must be included.
        [
          'gallery-02.jpg',
          'gallery-03.jpg',
          'gallery-01.jpg',
          'gallery-06.png?a=1',
          'gallery-04.jpg',
          'gallery-07.jpg',
          'gallery-08.jpg',
          'gallery-10.jpg',
          'gallery-09.jpg',
          'gallery-11.jpg',
          'gallery-12.jpg',
          'gallery-13.jpg',
        ],
        // _shuffle([
        //   'gallery-01.jpg',
        // ]),
      ],
      marqueeSpeed: 140,
    }} />,
  },
  /**
   * Accordion.
   */
  {
    component: () => <Accordion config={{
      title: 'How to buy Andy', // Optional | falsy to omit.
      titleColorId: 'tertiary.main', // Required | 'primary.main' / 'secondary.main'.
      bgColor: 'transparent', // Required | full hex color or 'transparent' | 'transparent' if a bgImgSrc is configured.
      bgImgSrc: 'bg-7.jpg', // e.g. 'appBgImg.png' | falsy for no image.
    }} />,
  },
  /**
   * Text image.
   */
  {
    component: () => <TextImg config={{
      alignId: 'CENTER', // Required | 'CENTER' / 'TEXT_LEFT' / 'TEXT_RIGHT'.
      alignContent: 'center', // Required | 'center' / 'flex-start'.
      title: 'Andynomics', // Optional | falsy to omit.
      titleColorId: 'tertiary.main', // Required | 'primary.main' / 'secondary.main'.
      titleBgColor: '', // Optional | HEX color or falsy for no color.
      subTitle: '', // Optional | falsy to omit.
      subTitleColorId: '', // Required | 'primary.main' / 'secondary.main'.
      subTitleBgColor: '', // Optional | HEX color or falsy for no color.
      isTitleBeforeSubTitle: false, // Required | when false the H2 appears before H1.
      paragraphs: [ // Optional | can include HTML content.
        '💦 86% added to liquidity.',
        '🔊 10% for marketing.',
        '👥 2% for the team.',
        '🐸 2% for Matt Furie + donations - feels good man.',
        '🔥 Liquidity burnt.',
        '⚖️ 0/0 tax.',
        '🔒 Contract is renounced.',
      ],
      imgSrc: '', // Optional | falsy for no image.
      imgWidthPx: 400, // Required when imgSrc exists.
      bgColor: 'transparent', // Required | full hex color or 'transparent' | 'transparent' if a bgImgSrc is configured.
      bgImgSrc: 'bg-4.jpg', // e.g. 'appBgImg.png' | falsy for no image.
    }} />,
  },
  /**
   * Text image.
   */
  {
    component: () => <TextImg config={{
      alignId: 'CENTER', // Required | 'CENTER' / 'TEXT_LEFT' / 'TEXT_RIGHT'.
      alignContent: 'center', // Required | 'center' / 'flex-start'.
      title: 'Why Andy?', // Optional | falsy to omit.
      titleColorId: 'tertiary.main', // Required | 'primary.main' / 'secondary.main'.
      titleBgColor: '', // Optional | HEX color or falsy for no color.
      subTitle: '', // Optional | falsy to omit.
      subTitleColorId: '', // Required | 'primary.main' / 'secondary.main'.
      subTitleBgColor: '', // Optional | HEX color or falsy for no color.
      isTitleBeforeSubTitle: false, // Required | when false the H2 appears before H1.
      paragraphs: [ // Optional | can include HTML content.
        '🟡 Memecoins are the most profitable sector in Q1 2024.',
        '🟡 The Boy\'s Club continues to be the most successful memecoin narrative this cycle.',
        '🟡 According to Pepe lore, Boy\'s Club characters are equals (best friends & roommates), not competitors. So where one goes, the others follow, and if bro Brett recently passed a 1.9B MC then...',
        '🟡 Andy is on the hottest chain, Base.',
        '🟡 Base is supported by the second largest cryptocurrency exchange, Coinbase (<em>normie</em> on-ramp central).',
        '🟡 Andy has been created with a long term view, aiming to be one of the strongest memecoins heading into the bullrun.',
        '🟡 From the creation of studio quality artwork and viral content to ensuring safety to maintaining full transparency to networking with the right people, the team believes in a more professional approach.',
        '🟡 Andy\'s marketing manager is experienced, well connected, and only works with the best.',
        '🟡 Continued and growing support from multiple respected <em>chads</em> within the space.',
        '🟡 Yellow is the new green.',
      ],
      imgSrc: '', // Optional | falsy for no image.
      imgWidthPx: 400, // Required when imgSrc exists.
      bgColor: 'transparent', // Required | full hex color or 'transparent' | 'transparent' if a bgImgSrc is configured.
      bgImgSrc: 'bg-9.jpg', // e.g. 'appBgImg.png' | falsy for no image.
    }} />,
  },
  /**
   * Video.
   */
  {
    component: () => <Video config={{
      title: 'Meet the gang', // Optional | falsy to omit.
      titleColorId: 'tertiary.main', // Required | 'primary.main' / 'secondary.main'.
      titleBgColor: '', // Optional | HEX color or falsy for no color.
      subTitle: ''.toUpperCase(), // Optional | falsy to omit.
      subTitleColorId: '', // Required | 'primary.main' / 'secondary.main'.
      subTitleBgColor: '', // Optional | HEX color or falsy for no color.
      isTitleBeforeSubTitle: true, // Required | when false the H2 appears before H1.
      addPaddingBottom: true,
      bgColor: 'transparent', // Required | full hex color or 'transparent' | 'transparent' if a bgImgSrc is configured.
      bgImgSrc: 'bg-5.jpg', // Optional | e.g. 'test.png' | falsy for no image.
      embedId: 'MuhzsdJKRuk', // Required | YouTube embed ID.
    }} />,
  },
  /**
   * Footer.
   */
  {
    component: () => <Footer config={{
      tokenName: `${TOKEN_SYMBOL}`, // Required.
      bgColor: 'transparent', // Required | set to 'transparent' or a full color e.g. '#CCCCCC'.
      socials, // Required.
    }} />,
  },
];

/**
 * Exports.
 */
export const appConfig = {
  tabTitle: APP_TAB_TITLE, // Default browser tab title.
  home: {
    tabTitle: APP_TAB_TITLE, // Browser tab title.
  },
  sections,
  maxContainerWidthPx: MAX_CONTAINER_WIDTH_PX,
  containerGutterHorizontalRem: CONTAINER_GUTTER_HORIZONTAL_REM,
};