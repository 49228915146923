import React from "react";

const Icon = props => (
  <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" focusable="false" {...props}>
    <path fill="none" d="M0 0h30v30H0z"/>
    <path fill="currentColor" d="M21.38 5.63C15 1.13 8.25 0 6.25 2.88L.5 11.25c-1.44 2.45.63 6.6 4.78 10.43a29.87 29.87 0 0 0 3.35 2.82c6.37 4.38 13.12 5.63 15.12 2.75l5.75-8.5c1.88-2.87-1.62-8.87-8.12-13.12Zm7.25 12.62c-1.75 2.5-7.88 1.25-13.75-2.87S5.38 6 7.13 3.5 15 2.25 20.88 6.38s9.37 9.37 7.75 11.87Z" />
    <path fill="currentColor" d="M19.38 8.63c-3-2-6-2.63-6.88-1.38s.88 3.88 3.75 5.88 6 2.62 6.88 1.37-.88-3.87-3.75-5.87Z"/>
  </svg>
);

export default Icon;
