import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import _map from 'lodash/map';

import CssBaseline from '@mui/material/CssBaseline';

import Page from './page/Page';
import Theme from '../styles/Theme';

import store from '../store/config';

const renderRoutes = appRoutes =>
  _map(appRoutes, (route, idx) => {
    const { path, to, exact, component } = route;

    let render;

    if (component) {
      render = props => {
        const Component = component;

        return <Component {...props} />;
      };
    } else if (to) {
      render = () => {
        return <Redirect to={to} />;
      };
    }

    return (
      <Route
        key={`route${idx}`}
        path={path}
        exact={exact}
        render={render}
      />
    );
  });

const App = ({ routes }) => {
  return (
    <Provider store={store}>
      <Router>
        <Theme>
          <CssBaseline />
          <Page>
            <Switch>{renderRoutes(routes)}</Switch>
          </Page>
        </Theme>
      </Router>
    </Provider>
  );
};

App.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default App;
